export default function StatusExplanation({pasportData, className}) {
  const statusExplanation = (pasportData) => {
    if (!pasportData.internalStatus) {
      return null;
    }
    switch (pasportData.internalStatus.percent) {
      case 5:
        return 'видимо про принятие заявления в консульстве (по косвенным признакам - ждет отправления диппочтой в РФ, видимо цифровой документооборот пока не дошел)';
      case 10:
        return 'видимо про отправку заявления диппочтой в РФ (я видел как в одном консульстве куча номеров заявлений копилась полтора месяца и только потом отправилась)'
      case 20:
        return 'что-то внутреннее'
      case 30:
        return 'видимо, госбезопасность не спит'
      case 60:
        return 'видимо, госбезопасность убедилась, что доступа к гостайне у подавшего заявление нет'
      case 70:
        return 'по отрывочным сообщениям в интернете, "персонализация" - это печать паспорта Гознаком.'
      case 80:
        return <>Статус <b>"паспорт поступил"</b> вероятнее всего означает что напечатанный паспорт приехал с Гознака в МИД, <b>"паспорт отправлен в РКЗУ"</b> означает что паспорт отправлен диппочтой в российское консульское загранучреждение(РКЗУ).<br /><br />Если заявление длительное время (более месяца) находится в статусе "паспорт отправлен в РКЗУ" - это повод связаться с РКЗУ и уточнить по поводу прибытия паспорта в консульство.</>

      case 100:
      default:
        return null
    }
  }
  return <div className={className}>{statusExplanation(pasportData)}</div>
}