import './App.css';
import { MainPage } from './components/index'
//import { Routes, Route, Link } from 'react-router-dom'

function App() {
  return (
      <MainPage/>
  );
}

export default App;
